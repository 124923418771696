<template>
  <div>
    <div class="calContainer" :key="updateKey">
      <div class="flex flex-gap-5">
        <a
          v-for="(calendar, index) in $store.getters.assistents"
          class="flix-btn"
          href="#"
          :class="
            Object.keys(checked).includes(calendar.ID)
              ? 'flix-btn-success'
              : 'flix-btn-default'
          "
          type="button"
          style="display: flex; gap: 5px"
          :key="index"
          @click.prevent="setChecked(calendar.ID)"
        >
          {{ calendar.title }}
          <span class="flix-badge"
            ><flix-icon
              :id="
                Object.keys(checked).includes(calendar.ID) ? 'minus' : 'plus'
              "
          /></span>
        </a>
      </div>
      <div :key="Object.keys(checked).length" style="flex: 1; width: 100%">
        <b-flix-orderlist
          style="width: 100%"
          v-if="Object.keys(checked).length && Object.keys(checked).length > 1"
          :callback="setReorder"
        >
          <li v-for="check in sorted" :key="check">
            {{ $store.state.assistents.assistentsByID[check].title }}
          </li>
        </b-flix-orderlist>
      </div>
    </div>
    <div
      style="text-align: right"
      v-if="Object.keys(checked).length && Object.keys(checked).length > 1"
    >
      <a href="#" class="flix-html-a" @click.prevent="orderABC"
        ><flix-icon id="list"></flix-icon> ABC</a
      >
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    ressource: Object
  },
  data() {
    return {
      checked: {},
      sorted: [],
      updateKey: new Date().getTime()
    }
  },
  methods: {
    orderABC() {
      const names = []
      this.sorted.forEach((ID, index) => {
        names.push(
          this.$store.state.assistents.assistentsByID[ID].title +
            Math.ceil(Math.random() * 1000)
        )
      })

      const orderedNames = Object.values(names)
      orderedNames.sort()

      const newSorting = []
      orderedNames.forEach((name) => {
        newSorting.push(this.sorted[names.indexOf(name)])
      })
      this.sorted = newSorting
      this.setSaveCalendar()
      this.updateKey = new Date().getTime()
    },
    setReorder(from, to) {
      this.sorted.splice(to, 0, this.sorted.splice(from, 1)[0])
      this.setSaveCalendar()
      this.updateKey = new Date().getTime()
    },
    setChecked(id) {
      if (this.sorted.includes(id)) {
        this.sorted.splice(this.sorted.indexOf(id), true)
      } else {
        this.sorted.push(id)
      }

      if (typeof this.checked[id] !== 'undefined') {
        delete this.checked[id]
        this.setSaveCalendar()
        this.updateKey = new Date().getTime()
        return false
      }
      this.checked[id] = true
      this.setSaveCalendar()
      this.updateKey = new Date().getTime()
    },
    getSavedChecked() {
      if (
        typeof this.$store.getters.ressourcesConnect !== 'object' ||
        !Object.keys(this.$store.getters.ressourcesConnect).length
      ) {
        return false
      }
      if (
        typeof this.$store.getters.ressourcesConnect[this.ressource.ID] !==
          'object' ||
        !Object.keys(this.$store.getters.ressourcesConnect[this.ressource.ID])
          .length
      ) {
        return false
      }

      if (
        typeof this.$store.getters.ressourcesConnect[this.ressource.ID]
          .calendar === 'undefined'
      ) {
        return false
      }

      this.sorted =
        this.$store.getters.ressourcesConnect[this.ressource.ID].calendar

      this.sorted.forEach((v) => {
        this.checked[v] = true
      })

      this.updateKey = new Date().getTime()
    },
    setSaveCalendar() {
      var send = {
        user: this.$store.getters.user.md5_id,
        ID: this.ressource.ID,
        data: {
          calendar: this.sorted
        }
      }
      this.$flix_post({
        url: 'ressources/connect/save_calendar',
        data: send,
        callback: (ret) => {}
      })
    }
  },
  mounted() {
    this.getSavedChecked()
  },
  computed: {}
}
</script>
<style lang="sass" scoped>
.calContainer
  display: flex
  align-items: center
  flex-direction: column
  .calInsideContainer
    display: flex
    align-items: center
    .tinyicon
      font-size: 14pt
      margin-right: 3px
  .icon
    font-size: 24pt
    margin-right: 12px
  span
    padding-top: 4px
</style>
